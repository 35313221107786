<template>
  <div class="tw-bg-gray-100 tw-p-2 tw-shadow tw-h-full">
    <div class="tw-flex tw-items-center tw-justify-between">
      <h4 class="tw-text-xl">User Variable</h4>
      <Input
        v-model="search"
        :placeholder="$t('search')"
        style="width: 200px"
        class="btn-sm"
      />
    </div>
    <ts-panel>
      <div id="container-table">
        <ts-table
          :columns="columns"
          :records="userVariables"
          :loading="loading"
          mouse-type-on-row="pointer"
          @row-dblclick="onRowDblClick"
        >
          <template v-slot="{ record }">
            <td>{{ record.uv_name }}</td>
            <td>{{ record.description }}</td>
            <td>{{ record.data_type }}</td>
          </template>
        </ts-table>
      </div>
    </ts-panel>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { debounce } from "lodash";

export default {
  name: "user-variables",
  data() {
    return {
      columns: [
        { name: "Variable Name" },
        { name: "Description" },
        { name: "Data Type" },
      ],
      loading: false,
      search: "",
    };
  },
  computed: {
    ...mapState("payroll/payUserVariable", ["userVariables"]),
  },
  methods: {
    ...mapActions("payroll/payUserVariable", ["getUserVariables"]),
    onRowDblClick({ record }) {
      this.$emit("select-variable", record.uv_name);
    },
  },
  watch: {
    search: debounce(function (search) {
      this.loading = true;

      this.getUserVariables({ search }).then(() => {
        this.loading = false;
      });
    }, 500),
  },
};
</script>

<style lang="css" scoped>
#container-table {
  height: 30vh;
  overflow-y: auto;
}
</style>
